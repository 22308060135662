// constant
export const gridSpacing = 3
export const drawerWidth = 260
export const appDrawerWidth = 320
export const maxScroll = 100000
export const baseURL = process.env.NODE_ENV === 'production' ? window.location.origin : window.location.origin.replace(':8080', ':3000')
export const uiBaseURL = window.location.origin
export const FLOWISE_CREDENTIAL_ID = 'FLOWISE_CREDENTIAL_ID'
export const REDACTED_CREDENTIAL_VALUE = '_FLOWISE_BLANK_07167752-1a71-43b1-bf8f-4f32252165db'
// 临时常量定义
export const NODES_CATEGORY = {
    Agents: {
        name: '代理(Agents)'
    },
    Cache: {
        name: '缓存(Cache)'
    },
    Chains: {
        name: '链(Chains)'
    },
    'Chat Models': {
        name: '语言模型(Chat Models)'
    },
    'Document Loaders': {
        name: '文件解析器(Document Loaders)'
    },
    Embeddings: {
        name: '嵌入(Embeddings)'
    },
    LLMs: {
        name: 'LLMs'
    },
    Memory: {
        name: '数据存储(Memory)'
    },
    Moderation: {
        name: 'Moderation'
    },
    'Output Parsers': {
        name: '输出解析(Output Parsers)'
    },
    Prompts: {
        name: '交互(Prompts)'
    },
    Retrievers: {
        name: '检索(Retrievers)'
    },
    'Text Splitters': {
        name: '文本分割器(Text Splitters)'
    },
    Tools: {
        name: '工具(Tools)'
    },
    Utilities: {
        name: 'Utilities'
    },
    'Vector Stores;DEPRECATING': {
        name: 'Vector Stores'
    },
    'Vector Stores;NEW': {
        name: 'Vector Stores'
    }
}
